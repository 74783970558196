(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("node-fetch"), require("form-data"));
	else if(typeof define === 'function' && define.amd)
		define(["node-fetch", "form-data"], factory);
	else if(typeof exports === 'object')
		exports["ConnectyCube"] = factory(require("node-fetch"), require("form-data"));
	else
		root["ConnectyCube"] = factory(root["node-fetch"], root["form-data"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__32__, __WEBPACK_EXTERNAL_MODULE__93__) {
return 